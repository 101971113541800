import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading } from "components/misc/Headings.js";

import defaultCardImage from "../../images/shield-icon.svg";

import { ReactComponent as SvgDecoratorBlob3 } from "../../images/svg-decorator-blob-3.svg";

import SupportIconImage from "../../images/support-icon.svg";
import ShieldIconImage from "../../images/shield-icon.svg";
import CustomizeIconImage from "../../images/customize-icon.svg";
import FastIconImage from "../../images/fast-icon.svg";
import ReliableIconImage from "../../images/reliable-icon.svg";
import SimpleIconImage from "../../images/simple-icon.svg";

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto pt-20 md:pt-24`}
`;
const Heading = tw(SectionHeading)`w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 px-6 flex`}
`;

const Card = styled.div`
  ${tw`flex flex-col mx-auto max-w-xs items-center px-6 py-6 border-2 border border-primary-500 rounded-lg mt-10`}
  .imageContainer {
    ${tw`border-2 border-primary-500 text-center rounded-full p-6 flex-shrink-0 relative`}
    img {
      ${tw`w-8 h-8`}
    }
  }

  .textContainer {
    ${tw`text-center`}
  }

  .title {
    ${tw`font-bold text-xl leading-none text-primary-500`}
  }

  .description {
    ${tw`mt-3 font-semibold text-secondary-100 text-sm leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

export default () => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component):
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const cards = [
    {
      imageSrc: ShieldIconImage,
      title: "Accounting & Bookkeeping",
      description: "Use a suite of top-of-the-line accounting software to maintain records of all business financial transactions & accounts are reconciled to ensure their accuracy"
    },
    {
        imageSrc: FastIconImage,
        title: "Payroll Service",
        description: "Help with business payroll provessing, payroll taxes (federal & state), W2-1099, payroll tax problems, and IRS wage garnishment"
    },
    {
        imageSrc: SimpleIconImage,
        title: "Sales Tax",
        description: "Provide affordable, precise, and timely tax reporting. Clients are always informed of the appropriate conducts of sales tax."
    },
    {
        imageSrc: SupportIconImage,
        title: "Tax Services",
        description: "Help with 1120, 1120 S, 1065 tax form preparation"
    },
    {
        imageSrc: CustomizeIconImage,
        title: "Financial Reporting & Analysis",
        description: "Detailed accounting analysis to guide your decision-making."
    },
    {
        imageSrc: SupportIconImage,
        title: "IRS Complications Support",
        description: "Able to potentially assist with IRS penalties, IRS payment plans, and other financial guidance relating to IRS tax matters"
    },
    {
        imageSrc: FastIconImage,
        title: "Starting/Closing a Business",
        description: "Provide cost-effective counseling to help potential business owners start up their businesses or help existing business owners with the appropriate actions to close their business"
    },
  ];

  return (
    <Container>
      <ThreeColumnContainer>
        <Heading>Our Professional <span tw="text-primary-500">Services</span></Heading>
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
                {/*
              <span className="imageContainer">
                <img src={card.imageSrc || defaultCardImage} alt="" />
              </span>
              */}
              <span className="textContainer">
                <span className="title">{card.title}</span>
                <p className="description">
                  {card.description || "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud. Sic Semper Tyrannis. Neoas Calie artel."}
                </p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
      <DecoratorBlob />
    </Container>
  );
};
