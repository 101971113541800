import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

import Eagle from "../../images/eagle.png";
import Bookkeeping from "../../images/bookkeeping.png";

import Header, { NavLink, NavLinks, PrimaryLink, LogoLink, NavToggle, DesktopNavLinks } from "../headers/light.js";

const StyledHeader = styled(Header)`
  ${tw`pt-16 max-w-none`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-100 hover:border-gray-300 hover:text-gray-300`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`;
const Container = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`relative -mx-8 -mt-8 bg-center bg-cover`}
`;

const OpacityOverlay = styled.div`
    ${tw`z-10 absolute inset-0 bg-black`}
    opacity: 85%;
`;

const HeroContainer = tw.div`z-20 relative px-4 sm:px-8 max-w-screen-xl mx-auto`;
const TwoColumn = tw.div`pt-24 pb-32 px-4 flex justify-between items-center flex-col lg:flex-row`;
const LeftColumn = tw.div`flex flex-col items-center sm:w-5/6 lg:w-1/2 lg:block`;
const RightColumn = tw.div`w-full text-center sm:w-5/6 lg:w-1/2`;

const Heading = styled.h1`
  ${tw`text-4xl text-center sm:text-5xl lg:text-6xl xl:text-7xl font-black text-gray-100 leading-none`}
  span {
    ${tw`inline-block mt-2`}
  }
`;

const SlantedBackground = styled.span`
  ${tw`relative text-primary-500 text-center px-4 -mx-4 py-2`}
  &::before {
    content: "";
    ${tw`absolute inset-0 bg-gray-100 transform -skew-x-12 -z-10`}
  }
`;

const PrimaryAction = tw.a`inline-block px-8 py-3 mt-10 text-sm sm:text-base sm:mt-16 sm:px-8 sm:py-4 bg-gray-100 text-primary-500 font-bold shadow-lg transition duration-300 hocus:bg-primary-500 hocus:text-gray-100 focus:shadow-outline`;

export default () => {
  const navLinks = [
    <NavLinks key={1}>
        <NavLink rel="noopener noreferrer" href="https://goo.gl/maps/1twYEgYdegEc4AREA" target="_blank">
          Visit Our Location
        </NavLink>
      <PrimaryLink href="https://sa.www4.irs.gov/irfof/lang/en/irfofgetstatus.jsp">
        Check My Refund Status &#8594;
      </PrimaryLink>
    </NavLinks>
  ];

  return (
    <Container imageSrc={Eagle}>
      <OpacityOverlay />
      <HeroContainer>
        <StyledHeader links={navLinks} />
        <TwoColumn>
          <LeftColumn>
              <img src={Bookkeeping} alt="Accounting" style={{ width: '40vw', minWidth: '400px' }}/>
          </LeftColumn>
          <RightColumn>
              <Heading>
                <span>Hire the best</span>
                <br />
                <SlantedBackground>Accounting Team.</SlantedBackground>
              </Heading>
              <PrimaryAction href='mailto:info@eagletaxnj.com'>
                Email Us
              </PrimaryAction>
              <PrimaryAction href='tel:+19739254405'>
                Call Us
              </PrimaryAction>
          </RightColumn>
        </TwoColumn>
      </HeroContainer>
    </Container>
  );
};
