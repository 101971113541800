import React from "react";
import tw from "twin.macro"; //eslint-disable-line
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

import Hero from "components/hero/BackgroundAsImage.js";
import Services from "components/features/DashedBorderSixFeatures";
import MainFeature2 from "components/features/TwoColWithTwoFeaturesAndButtons.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndProfilePictureReview.js";
import FAQ from "components/faqs/SimpleWithSideImage.js";
import ContactUsForm from "components/forms/TwoColContactUsWithIllustration.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import customerSupportIllustrationSrc from "images/customer-support-illustration.svg";

import mohammadmohammad from './images/mohammadmohammad.png';
import mousahamad from './images/mousahamad.png';
import ahmadhamdeh from './images/ahmadhamdeh.png';

import accounting1 from './images/accounting1.jpg';
import accounting2 from './images/accounting2.jpg';
import accounting3 from './images/accounting3.jpg';

const Wrapper = styled.div`${tw`font-display`}`;


export default () => (
    <Wrapper>
        <Hero />
        <Services />
        <MainFeature2 />
        <Testimonial
            subheading="Testimonials"
            heading={
                <>
                    Our Clients <span tw="text-primary-500">Love Us.</span>
                </>
            }
            description="Don't take our word for it. See what our customers have to say."
            testimonials={[
                {
                    imageSrc: accounting1,
                    profileImageSrc: mohammadmohammad,
                    quote:
                        "This is the best accounting office in the state and they can help you with so many things and very knowledgeable. Trust me you won’t regret switching to this place.",
                    customerName: "Mohammad Mohammad",
                    customerTitle: "",
                },
                {
                    imageSrc: accounting2,
                    profileImageSrc: ahmadhamdeh,
                    quote: "Great place. They handle all of my paperwork. Easy to work with and friendly.",
                    customerName: "Ahmad Hamdeh",
                    customerTitle: "",
                },
                {
                    imageSrc: accounting3,
                    profileImageSrc: mousahamad,
                    quote: "Professional and speedy service. Would highly recommend.",
                    customerName: "Mousa Hamad",
                    customerTitle: "",
                },
            ]}
            textOnLeft={true}
        />
        <FAQ
            imageSrc={customerSupportIllustrationSrc}
            imageContain={true}
            imageShadow={false}
            subheading="FAQs"
            heading={
                <>
                    <span tw="text-primary-500">F</span>requently <span tw="text-primary-500">A</span>sked <span tw="text-primary-500">Q</span>uestions
                </>
            }
        />
        <ContactUsForm />
        <Footer />
    </Wrapper>
);
