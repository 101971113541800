import "tailwindcss/dist/base.css";
import "styles/globalStyles.css";
import React from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { css } from "styled-components/macro"; //eslint-disable-line
import MainLandingPage from "MainLandingPage.js";


export default function App() {
    return (
        <Router>
            <Switch>
                <Route path="/" exact>
                    <MainLandingPage />
                </Route>
                <Redirect to="/" />
            </Switch>
        </Router>
    );
}
